<script>
import {
  ref, computed, nextTick, onMounted, onUnmounted,
} from '@vue/composition-api'
import {
  VAutocomplete, VTextField, VSelect, VCombobox,
} from 'vuetify/lib/components'
import DateRangePickers from '@core/components/search-form/DateRangePickers'
import VCascader from '@core/components/search-form/VCascader.vue'
import { compareOptions } from '@core/utils/options'
import useAppConfig from '@core/@app-config/useAppConfig'
import { can } from '@core/utils/useAccess'

export default {
  name: 'SearchForm',
  components: {
    VAutocomplete, VTextField, DateRangePickers, VSelect, VCombobox, VCascader,
  },
  props: {
    // 默认显示条数
    colSpan: {
      type: Number,
      default: 2,
    },

    // form 配置列表
    formConfig: {
      type: Array,
      default: () => [],
    },

    // 是否需要重置
    needReset: {
      type: Boolean,
      default: true,
    },

    // 是否显示全部查询条件
    showAll: {
      type: Boolean,
      default: false,
    },

    // 是否点击重置给你自动调用search事件
    restIsSearch: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const isOpen = ref(props.showAll)
    const form = ref({})
    const formWrapRef = ref()
    const formWrapWidth = ref(0)

    const { isDark } = useAppConfig()

    let datePicksKeys

    const initFormValue = () => {
      const initFormData = {}
      datePicksKeys = []
      props.formConfig.forEach(item => {
        // 日期选择搜索字段对应多个key
        if (Array.isArray(item.searchKey)) {
          item.searchKey.forEach((key, index) => {
            initFormData[key] = (item.initValue && item.initValue[index]) || null
          })
          datePicksKeys.push(item.props)
        }

        initFormData[item.props] = item.initValue || item.initValue === 0 ? item.initValue : null

        if (item.slotCompare) {
          initFormData[item.slotCompare.props] = item.slotCompare.initValue ? item.slotCompare.initValue : compareOptions[0].value
        }
      })

      return initFormData
    }

    form.value = initFormValue()

    setTimeout(() => {
      form.value = initFormValue()
    }, 1000)

    const setInitFormValue = () => {
      form.value = initFormValue()
    }

    const search = () => {
      // 处理别名search key问题
      const hasSearchKeyItems = props.formConfig.filter(x => x.searchKey) || []
      const searchKeyMap = {}

      hasSearchKeyItems.forEach(item => {
        searchKeyMap[item.props] = item.searchKey
      })

      Object.keys(searchKeyMap).forEach(key => {
        if (Array.isArray(searchKeyMap[key])) {
          searchKeyMap[key].forEach((item, index) => {
            form.value[item] = form.value[key] || form.value[key] === 0 ? form.value[key][index] : null
          })
        } else {
          form.value[searchKeyMap[key]] = form.value[key]
        }
      })

      // 删除时间选择的key
      const resParams = { ...form.value }
      datePicksKeys.forEach(key => {
        delete resParams[key]
      })

      // 传递当前展开状态
      if (props.showAll) {
        emit('search', { ...resParams, isOpen: isOpen.value })
      } else {
        emit('search', { ...resParams })
      }
    }

    // 重置表单基础配置
    // const resetFormData = () => {
    //   initFormValue()
    // }

    // 重置
    function reset() {
      emit('reset')
      nextTick(() => {
        form.value = initFormValue()
        if (props.restIsSearch) {
          search()
        }
      })
    }

    const showMore = computed(() => index => index <= Math.max(Math.min(props.colSpan, formWrapWidth.value / 300 - 2), 0) || isOpen.value)

    const handleChange = (key, value) => {
      // search()
      emit('change', { key, value })
    }

    const handleInput = (key, value) => {
      emit('input', { key, value })
    }

    // 自适应屏幕宽度
    let timer = null
    const getFormWidth = () => {
      if (timer) return
      timer = setTimeout(() => {
        formWrapWidth.value = formWrapRef.value.offsetWidth
        timer = null
      }, 500)
    }

    onMounted(() => {
      nextTick(() => {
        getFormWidth()
      })

      window.addEventListener('resize', getFormWidth)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', getFormWidth)
    })

    return {
      formWrapRef,
      isOpen,
      form,
      showMore,
      isDark,
      compareOptions: ref(compareOptions),
      search,
      reset,
      can,
      handleChange,
      setInitFormValue,
      handleInput,

      // resetFormData,
    }
  },
}
</script>
<template>
  <div
    ref="formWrapRef"
    class="form-wrap"
    :class="isDark ? 'them--dark' : 'them--light'"
  >
    <div
      v-for="(item, index) in formConfig"
      v-show="showMore(index)"
      :key="item.props"
      class="form-item"
    >
      <template v-if="!item.auth || can(item.auth)">
        <label
          class="form-label"
          :title="item.label"
        >{{ item.label }}</label>
        <div class="form-input">
          <component
            :is="item.element"
            v-model.trim="form[item.props]"
            :clearable="item.clearable === undefined ? true : item.clearable"
            :placeholder="item.label"
            label=""
            dense
            hide-details
            outlined
            single-line
            v-bind="item"
            :class="item.slotCompare ? 'form-compare': ''"
            @keyup.enter.native="search"
            @change="(value) => {handleChange(item.props, value)}"
            @input="(value) => {
              item.needInt ? form[item.props] = value.replace(/^(0+)|[^\d]+/g,'') : (typeof value === 'String' ? form[item.props] = value.trim() : value)
              handleInput(item.props, value)
            }"
          >
            <template
              v-if="item.slotCompare"
              #prepend-inner
            >
              <v-select
                v-model="form[item.slotCompare.props]"
                :items="compareOptions"
                item-value="value"
                item-text="label"
                dense
                outlined
                hide-details
                single-line
                v-bind="item.slotCompare"
                :menu-props="{ auto: true, offsetY: true }"
                class="ml-n3"
                style="width: 90px"
              >
              </v-select>
            </template>
            <template #selection="{ item: itemChild, index: indexChild }">
              <span v-if="indexChild === 0">
                <span>{{ itemChild.text || itemChild[item.itemText] || itemChild }}</span>
              </span>
              <span
                v-if="indexChild === 1"
                class="grey--text text-caption"
              >
                (+{{ form[item.props].length - 1 }} others)
              </span>
            </template>
          </component>
        </div>
      </template>
    </div>
    <div class="form-btns">
      <v-btn
        v-if="needReset"
        outlined
        class="btn-item mr-3"
        depressed
        @click="reset"
      >
        重置
      </v-btn>
      <v-btn
        color="primary"
        class="btn-item"
        depressed
        @click="search"
      >
        查询
      </v-btn>
      <span
        v-if="formConfig.length > (colSpan + 1)"
        style="color: var(--v-primary-base); font-size: 14px"
        @click="isOpen = !isOpen"
      >
        {{ isOpen ? '收起':'展开' }}
        <v-icon
          v-if="isOpen"
          size="20"
          class="me-2"
          color="primary"
          style="margin-left: -5px"
        >mdi-chevron-up
        </v-icon>
        <v-icon
          v-else
          size="20"
          class="me-2"
          color="primary"
          style="margin-left: -5px"
        >mdi-chevron-down
        </v-icon>
      </span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.form-wrap{
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 12px;
  box-shadow: inset 0px -1px 0px 0px #E8E9EC;
  .form-item{
    display: flex;
    align-items: center;
    padding: 6px 0;
    margin-right: 30px;
    min-width: 300px;
    max-width: 400px;
    display: flex;
    .form-input{
      flex: 1;
      ::v-deep .v-label{
        font-size: 14px;
      }
      ::v-deep .v-text-field.v-text-field--solo .v-input__control{
        min-height: 40px!important;
      }
      ::v-deep .v-input__control .v-input__slot{
        min-height: 40px!important;
        max-width: 250px;
        box-sizing: border-box;
        .v-input__prepend-inner{
          margin-top: 0!important;
        }

        .v-input__append-inner{
          display: flex;
          align-items: center;
        }
      }
      ::v-deep .v-text-field__slot{
        width: 230px;
      }
      ::v-deep .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner .v-input__icon--append{
        margin-top: 0;
      }
      ::v-deep .v-select.v-input--dense .v-select__selection--comma{
        margin: 3px 4px 3px 0;
      }
      ::v-deep .v-select__slot{
        width: 250px;
      }
    }
    .form-label{
      width: 84px;
      padding: 0 8px 0 0;
      font-size: 14px;
      line-height: 16px;
      text-align: right;
      white-space: wrap; //不换行
      overflow: hidden; //隐藏溢出
      text-overflow: ellipsis; //省略号
      display: -webkit-box; //-webkit-box是兼容IOS和老版本Android浏览器的方式
      -webkit-line-clamp: 2; //限制行数
      -webkit-box-orient: vertical; //垂直方向排列
      color: rgba(0, 0, 0, 0.50);
    }
  }
  .form-compare{
    ::v-deep .v-text-field__slot{
      width: 115px;
    }
  }
  .form-btns{
    margin-left: auto;
    padding: 6px 0;
    display: flex;
    align-items: center;
    .btn-item{
      margin-right: 8px;
    }
  }
}
.form-wrap.them--dark{
  box-shadow: inset 0px -1px 0px 0px rgba(232, 233, 236, 0.1);
}
</style>
